import React from 'react';
import './BotDetail.scss';
import {
  Container, Col, Dropdown,
  Form, ButtonGroup, Row, Button,
} from 'react-bootstrap';
import BotHistoryTable from '../../components/BotHistoryTable/BotHistoryTable';
import BotHistoryChart from '../../components/BotHistoryChart/BotHistoryChart';
import {
  getBotHistoryRequestAction,
  getCustomBotHistoryRequestAction,
  setBotHistorySortDataAction,
  sortingBotHistoryAction,
} from '../../store/bot/botActions';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import MercurXBackButton from '../../components/MercurXBackButton/MercurXBackButton';
import { useParams, useLocation } from 'react-router-dom';
import { sortKeys, sortTypes } from './botDetailConstants';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import bnbusdt from '../../assets/img/coin/bnbusdt.png';

function BotDetail({ ...props }) {
  const {
    getBotHistoryRequest,
    botHistorySortData,
    setBotHistorySortData,
    sortingBotHistory,
    getBotHistoryRequestAction,
    getCustomBotHistoryRequestAction,
  } = props;

  const { id } = useParams();
  const location = useLocation();
  const item = location.state?.item;
  const exchanges = location.state?.exchanges;
  const [dateRange, setDateRange] = useState("1_day");
  const [selectedRange, setSelectedRange] = useState("1_day");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    const bot_id = id;
    const payload = {
      bot_id,
      dateRange,
    };
    console.log(payload);
    getBotHistoryRequestAction(payload);
  }, [botHistorySortData, dateRange]);

  useEffect(() => {
    getBotHistoryRequest();
    sortingBotHistory();
  }, []);

  function changeSortType(sortType) {
    const newBotHistorySortData = { ...botHistorySortData, sortType };
    setBotHistorySortData(newBotHistorySortData);
    sortingBotHistory();
  }

  function changeSortKey(sortKey) {
    const newBotHistorySortData = { ...botHistorySortData, sortKey };
    setBotHistorySortData(newBotHistorySortData);
    sortingBotHistory();
  }

  function handleDateRangeChange(range) {
    setDateRange(range);
    setSelectedRange(range);
  }

  function handleCustomSearch() {
    if (startDate && endDate) {
      const payload = {
        bot_id: id,
        startDate,
        endDate,
      };
      console.log("Custom search payload:", payload);
      getCustomBotHistoryRequestAction(payload);
    } else {
      console.error("Both start and end dates are required for custom search.");
    }
  }

  const formatRangeLabel = (range) => {
    return range.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase());
  };

  return (
    <Container>
      <MercurXBackButton buttonText="Back" />
      <div className="text-fs-head-sm text-start">Bot Detail</div>
      <div className='bot-chart-container'><BotHistoryChart /></div>
      {exchanges && exchanges[0].name && (
        <div className="exchange-name mx-3 text-end">
          <strong>Exchange: </strong>
          {exchanges[0].name.charAt(0).toUpperCase() + exchanges[0].name.slice(1).toLowerCase()}
          <img
            src={bnbusdt}
            width={24}
            height={24}
            style={{ marginLeft: 2, marginBottom: 3 }}
          />
        </div>

      )}


      <Row >
        <Col sm={12} md={6} className="mb-3 mt-3">
          <ButtonGroup className="d-flex">
            {["1_day", "1_week", "1_month", "3_month", "6_month", "1_year"].map((range) => (
              <Button
                key={range}
                onClick={() => handleDateRangeChange(range)}
                variant={selectedRange === range ? "primary" : "outline-light"}
                className={selectedRange === range ? "flex-fill" : " text-primary"}
              >
                {formatRangeLabel(range)}
              </Button>
            ))}
          </ButtonGroup>
        </Col>
        <Col></Col>
        <Col className='mt-3'>
          <Form.Group>
            <Form.Control
              type="date"
              value={startDate}
              onChange={(e) => {
                const selectedDate = e.target.value;
                setStartDate(selectedDate);
                if (endDate && new Date(selectedDate) > new Date(endDate)) {
                  setEndDate("");
                }
              }}
              max={endDate}
              placeholder="Start Date"
            />
          </Form.Group>
        </Col>
        <Col className='mt-3'>
          <Form.Group>
            <Form.Control
              type="date"
              value={endDate}
              onChange={(e) => {
                const selectedDate = e.target.value;
                setEndDate(selectedDate);
                if (startDate && new Date(selectedDate) < new Date(startDate)) {
                  setStartDate("");
                }
              }}
              min={startDate}
              placeholder={"End Date"}
            />
          </Form.Group>
        </Col>
        <Col className='mt-3'>
          <Button
            variant="primary"
            className="mt-1 text-fs-body-sm d-flex"
            onClick={handleCustomSearch}
          >
            <MercurXIcons name="FaSearch" className="" size="12" />
          </Button>
        </Col>
      </Row>
      <div className="text-fs-head-sm mt-4 text-start">Bot History</div>
      <Col>
        <div id="projects-sorting-section" className="d-flex align-items-center py-2">
          <Dropdown className="me-2 projects-table-button">
            <Dropdown.Toggle size={'sm'} className="d-flex align-items-center">
              <MercurXIcons name="MdSort" size="18" />
              <span className="ms-1">{sortTypes[botHistorySortData.sortType].name}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="py-1">
              {sortTypes.map((sortType, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    className="d-flex align-items-center px-1"
                    onClick={() => changeSortType(index)}
                  >
                    <MercurXIcons name={sortType.icon} size="18" />
                    <span className="ms-2">{sortType.name}</span>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="projects-table-button">
            <Dropdown.Toggle size={'sm'} className="d-flex align-items-center">
              <MercurXIcons name="BiFilterAlt" size="18" />
              <span className="ms-1">{sortKeys[botHistorySortData.sortKey].name}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="py-1">
              {sortKeys.map((sortKey, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    className="d-flex align-items-center px-1"
                    onClick={() => changeSortKey(index)}
                  >
                    <span>{sortKey.name}</span>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Col>
      <BotHistoryTable />
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    botHistoryData: state.botReducer.botHistoryData,
    botStatisticsData: state.botReducer.botStatisticsData,
    botHistorySortData: state.botReducer.botHistorySortData,
    isLoading: state.loadingReducer.isLoading,
    user: state.userReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBotHistoryRequest: (payload) => {
      dispatch(getBotHistoryRequestAction(payload));
    },
    setBotHistorySortData: (payload) => {
      dispatch(setBotHistorySortDataAction(payload));
    },
    sortingBotHistory: (payload) => {
      dispatch(sortingBotHistoryAction(payload));
    },
    getBotHistoryRequestAction: (creds) => {
      dispatch(getBotHistoryRequestAction(creds));
    },
    getCustomBotHistoryRequestAction: (creds) => {
      dispatch(getCustomBotHistoryRequestAction(creds));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BotDetail);

